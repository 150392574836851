import { IAuthResponse } from "./auth";
import * as authDeps from "./deps.authentication";
import { getPathPrefix, isCurrentDomainGtmhub, isQuantiveDomain, stripProtocolFromURL, switchToQuantiveDomain, toGtmhubDomain } from "./env/util";
import * as logging from "./logging";

declare let ghLogging: typeof logging;
declare let gtmhub: typeof authDeps;
declare let ghLoginConfig: { domainsAllowedForSignup: string[]; bypassDomainCheck?: boolean };
declare let Intercom: (action: string) => void;

const headers = gtmhub.createHttpRequestHeaders("gtmhub.login");
const searchParams = new URLSearchParams(window.location.search);
const paramEmail = searchParams.get("email");
const paramUserEmail = searchParams.get("user");

// If the user is associated with more than one workspace (domain) in Orbit,
// we will try to automatically select this one (if available). This is only
// used in cases where you are on the general login page (i.e. not on a vanity domain).
// The preferred domain should be in this format: xxx.gtmhub.com (or xxx.staging.gtmhub.com)
const paramPreferredDomain = searchParams.get("domain");

const paramIsSecondary = searchParams.get("isSecondary");
const paramReturnPath = searchParams.get("returnPath");
const errorMessageEl = document.getElementById("error-validation") as HTMLElement;
const errorMessageInputWrapper = document.querySelector(".input-wrapper") as HTMLElement;
const emailEl = document.getElementById("email") as HTMLInputElement;
const submitEl = document.getElementById("submit");
const loginWindowEl = document.getElementById("login-window");

const { hostname } = window.location;
const shouldAuthorizeImmediately = (!isGenericDomain(hostname) && !ghLoginConfig.bypassDomainCheck) || isValidEmail(paramEmail) || paramIsSecondary;
if (shouldAuthorizeImmediately) {
  const { protocol } = window.location;
  const domain = `${protocol}//${searchParams.get("originalDomain") || hostname}`;
  fetchAuthData(null, paramEmail, domain, paramIsSecondary, paramReturnPath);
} else {
  initEmailForm();
}

function initEmailForm(): void {
  showEmailForm();

  emailEl.addEventListener("keypress", function (e) {
    if (e.keyCode === 13) {
      submitEl.click();
    } else {
      errorMessageEl.style.display = "none";
      errorMessageInputWrapper.classList.remove("error");
    }
  });

  submitEl.addEventListener("click", function () {
    findUserDomain(emailEl.value);
  });

  submitEl.addEventListener("focus", function () {
    submitButtonDescribedby(emailEl.value);
  });

  if (paramUserEmail) {
    emailEl.value = paramUserEmail;
    submitEl.click();
  } else if (paramEmail) {
    emailEl.value = paramEmail;
    submitEl.click();
  }
}

function showEmailForm(): void {
  const loginWindowEl = document.getElementById("login-window");
  loginWindowEl.classList.remove("no-user", "multiple-accounts");
  document.body.classList.remove("working", "no-user", "multiple-accounts", "multiple-sso-connections");
  loginWindowEl.classList.add("enter-email");

  emailEl.focus();
}

function submitButtonDescribedby(email: string): void {
  if (isValidEmail(email)) {
    submitEl.removeAttribute("aria-describedby");
  } else {
    submitEl.setAttribute("aria-describedby", "error-validation");
  }
}

function isGenericDomain(hostname: string): boolean {
  const genericDomains = JSON.stringify(ghLoginConfig.domainsAllowedForSignup);
  return genericDomains.indexOf(hostname) >= 0;
}

function isValidEmail(email: string): boolean {
  const regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return regex.test(email);
}

function findUserDomain(email: string): void {
  if (isValidEmail(email)) {
    const domain = paramPreferredDomain
      ? paramPreferredDomain.startsWith("http://") || paramPreferredDomain.startsWith("https://")
        ? paramPreferredDomain
        : `https://${paramPreferredDomain}`
      : null;
    fetchAuthData(email, email, domain);
  } else {
    errorMessageEl.innerText = "The entered email is invalid.";
    errorMessageEl.style.display = "block";
    errorMessageInputWrapper.classList.add("error");
  }
}

function hideSignUpAndEmailButtons(): void {
  const signUpBtn = document.getElementById("login-new-account");
  const emailBtn = document.getElementById("show-email-form");

  [signUpBtn, emailBtn].forEach((btn) => (btn.style.display = "none"));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function safeJsonParseResponseText<T extends Record<string, any> = Record<string, any>>(responseText: string): T {
  try {
    return JSON.parse(responseText);
  } catch {
    return {} as T;
  }
}

function fetchAuthData(orbitEmail: string, authEmail: string, domain?: string, isSecondary?: string, returnPath?: string): void {
  const requestConfig = buildAuthRequest(orbitEmail, authEmail, domain, isSecondary, returnPath);
  $.ajax(requestConfig)
    .done(function (data) {
      const authResponse: IAuthResponse = data as IAuthResponse;
      handleAuthDataSuccess(authResponse, orbitEmail, authEmail, isSecondary);
    })
    .fail(function (jqXHR: JQuery.jqXHR<boolean>, textStatus) {
      document.body.classList.remove("working");

      const status = jqXHR.status;
      switch (status) {
        case 400: {
          handleAuthResponse400(jqXHR, orbitEmail, authEmail);
          break;
        }
        case 404: {
          handleAuthResponse404(orbitEmail);
          return;
        }
        case 409: {
          handleAuthResponse409(jqXHR, orbitEmail, authEmail);
          return;
        }
      }

      // any critical error
      showEmailForm();

      errorMessageEl.innerText = "There was a problem with our servers. Please try again.";
      errorMessageEl.style.display = "block";
      errorMessageInputWrapper.classList.add("error");

      ghLogging.logHttpError(requestConfig, { jqXHR, textStatus });

      if ("Intercom" in window) {
        Intercom("show");
      }
    });
}

function buildAuthRequest(orbitEmail: string, authEmail: string, domain: string, isSecondary: string, returnPath: string): JQuery.AjaxSettings<HTMLElement> {
  if (orbitEmail) {
    orbitEmail = orbitEmail.toLowerCase();
  }
  if (authEmail) {
    authEmail = authEmail.toLowerCase();
  }

  document.body.classList.add("working");
  document.querySelector(".working").setAttribute("aria-live", "assertive");

  loginWindowEl.classList.remove("enter-email");

  const data: Record<string, string> = {
    email: orbitEmail,
  };
  if (domain) {
    // While we support both .gtmhub.com and .quantive.com domains to work, Orbit understand only about .gtmhub.com domain currently
    data.domain = toGtmhubDomain(domain);
  }
  if (isSecondary) {
    data.isSecondary = isSecondary;
  }
  if (returnPath) {
    data.returnPath = returnPath;
  }

  const requestConfig: JQuery.AjaxSettings<HTMLElement> = {
    url: `${getPathPrefix()}/auth`,
    type: "POST",
    headers: headers,
    context: document.body,
    data,
  };

  return requestConfig;
}

function handleAuthResponse400(jqXHR: JQuery.jqXHR<boolean>, orbitEmail: string, authEmail: string): void {
  // when we have specified a domain that is invalid
  const { error } = safeJsonParseResponseText<{ error: string }>(jqXHR.responseText);
  if (error && error.startsWith("there are no workspaces corresponding to domain")) {
    // resubmit the form without the explicit domain
    setTimeout(() => fetchAuthData(orbitEmail, authEmail, null, null, paramReturnPath), 1);
  }
}

function handleAuthResponse404(orbitEmail: string): void {
  // no such e-mail
  loginWindowEl.classList.add("no-user");
  document.body.classList.add("no-user");
  document.getElementById("email-placeholder").innerText = orbitEmail;
  document.getElementById("try-with-another-email").focus();
}

function handleAuthResponse409(jqXHR: JQuery.jqXHR<boolean>, orbitEmail: string, authEmail: string): void {
  // multiple accounts
  loginWindowEl.classList.add("multiple-accounts");
  document.body.classList.add("multiple-accounts");
  const accountsListEl = document.getElementById("multiple-accounts-list");
  accountsListEl.innerHTML = "";

  const { domains } = safeJsonParseResponseText<{ domains: string[] }>(jqXHR.responseText);
  for (let i = 0; i < domains.length; i++) {
    const listEl = document.createElement("li");
    const accountEl = document.createElement("button");
    listEl.appendChild(accountEl);

    accountEl.innerText = domains[i].replace("https://", "");
    accountEl.addEventListener("click", function (e) {
      fetchAuthData(orbitEmail, authEmail, domains[i], null, paramReturnPath);
      e.preventDefault();
    });

    if (i === 0) {
      accountEl.classList.add("first-domain-el");
      const chooseWhichToLogIn = document.getElementById("choose-which-to-log-in").innerText;
      accountEl.setAttribute("aria-label", chooseWhichToLogIn + accountEl.innerText);
    }
    accountsListEl.appendChild(listEl);
  }

  const firstDomainEl = document.querySelector(".first-domain-el") as HTMLElement;
  firstDomainEl.focus();
}

function handleAuthDataSuccess(data: IAuthResponse, orbitEmail: string, authEmail: string, isSecondary?: string) {
  if (data.authMethod === "mixed") {
    // if we don't know the authentication method based on the domain only, we need the email
    initEmailForm();
    return;
  }
  // If Orbit wants us to continue on the quantive domain while we are on the gtmhub.com domain,
  // then we need to switch domains before we set any cookies
  if (isQuantiveDomain(data.gtmhubDomain) && isCurrentDomainGtmhub()) {
    switchToQuantiveDomain(authEmail, stripProtocolFromURL(data.gtmhubDomain), paramReturnPath);
    return;
  }

  // if sso connections and email has been provided -> redirect always with first connection in array
  if (data.connections && !isSecondary && orbitEmail) {
    gtmhub.navigateToAuth0Login(data, authEmail, {
      appState: paramReturnPath ? { returnPath: paramReturnPath } : undefined,
      connection: data.connections[0],
      bypassDomainCheck: ghLoginConfig.bypassDomainCheck,
    });
    return;
  }

  if (showMultipleConnectionList(data)) {
    handleMultipleSsoConnections(data, authEmail);
  } else if (data.connections && data.connections.length === 1) {
    gtmhub.navigateToAuth0Login(data, authEmail, {
      appState: paramReturnPath ? { returnPath: paramReturnPath } : undefined,
      connection: data.connections[0],
      bypassDomainCheck: ghLoginConfig.bypassDomainCheck,
    });
  } else {
    gtmhub.navigateToAuth0Login(data, authEmail, {
      appState: paramReturnPath ? { returnPath: paramReturnPath } : undefined,
      bypassDomainCheck: ghLoginConfig.bypassDomainCheck,
    });
  }
}

function handleMultipleSsoConnections(data: IAuthResponse, authEmail: string) {
  document.body.classList.remove("working");
  loginWindowEl.classList.add("multiple-sso-connections");
  document.body.classList.add("multiple-sso-connections");

  /** hide the 'use email' and 'sign up' buttons if we show multi-sso connections list */
  hideSignUpAndEmailButtons();

  const ssoListEl = document.getElementById("multiple-sso-connections-list");
  ssoListEl.innerHTML = "";

  for (const connection of data.connections || []) {
    const ssoEl = document.createElement("button");
    ssoEl.innerText = connection.displayName || connection.domain;
    ssoEl.onclick = ((clickedConnection: { connectionName: string; domain: string; displayName?: string }) => {
      return (event: MouseEvent) => {
        event.preventDefault();
        gtmhub.navigateToAuth0Login(data, authEmail, {
          appState: paramReturnPath ? { returnPath: paramReturnPath } : undefined,
          connection: clickedConnection,
        });
      };
    })(connection);
    ssoListEl.appendChild(ssoEl);
  }
}

function showMultipleConnectionList(data: IAuthResponse): boolean {
  if (data.connections && data.connections.length > 1) {
    for (const connection of data.connections || []) {
      if (connection.domain && connection.domain !== "") {
        return true;
      }
    }
  }
  return false;
}

const module = {
  showEmailForm,
  fetchAuthData,
};

declare global {
  interface Window {
    login: typeof module;
  }
}

window.login = module;
